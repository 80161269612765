define("apollo/pods/components/transports/transports-table/component", ["exports", "apollo/config/environment", "jquery", "apollo/mixins/persistence-mixin", "apollo/mixins/adjust-page-content-mixin", "apollo/utils/objects/TransportsTableFilters", "apollo/utils/objects/ArchiveTableFilters"], function (_exports, _environment, _jquery, _persistenceMixin, _adjustPageContentMixin, _TransportsTableFilters, _ArchiveTableFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_persistenceMixin.default, _adjustPageContentMixin.default, {
    apolloApiService: Ember.inject.service('api.apollo-api-service'),
    router: Ember.inject.service(),
    // TODO: Przenieść logikę filtrów do jakiegoś serwisu [A-10622]
    transportsTableFilters: Ember.computed('transportType', function () {
      return new _TransportsTableFilters.default(this.get('transportType'), this.get('sessionAccount.currentUser'));
    }),
    archiveTableFilters: Ember.computed('transportType', function () {
      return new _ArchiveTableFilters.default(this.get('transportType'), this.get('sessionAccount.currentUser'));
    }),
    FILTERS: Ember.computed('transportType', 'router.currentRouteName', function () {
      if (this.router.currentRoute.name.includes('archive')) {
        return this.get('archiveTableFilters');
      }

      return this.get('transportsTableFilters');
    }),
    // Zmienna pomocnicza służy do wyświetlania przycisku "Wyczyść filtry" w przypadku zmiany zakładki, gdy mamy zapisane filtry
    activeFiltersQueryParams: Ember.A([]),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    appTabActiveService: Ember.inject.service('application-tab-active-service'),
    attributeBindings: Ember.A(['data-transport-type']),
    transportType: null,
    archive: null,
    ROWS_INTERVAL: 7,
    // liczba wierszy renderowanych jednocześnie podczas wstępnego generowania tabeli
    ROWS_PER_PAGE: 30,
    numberOfRenderedRows: 7,
    // liczba wierszy aktualnie wyrenderowanych wstępnie
    DEFAULT_SORTING_KEY: 'earliestTimeWindowStart',
    sortingKey: 'earliestTimeWindowStart',
    descDirection: false,
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    searchParams: {},
    loadingMore: false,
    // true oznacza, że dodatkowe dane są ładowane
    highlightedTransportId: null,
    openTransportId: null,
    showColumnFilters: Ember.computed('transportTypeMode', 'searchParams.query', function () {
      return this.get('transportTypeMode') !== 'ALL' && this.get('sessionAccount').hasRole('ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE') && !this.get('searchParams.query');
    }).readOnly(),
    'data-transport-type': Ember.computed('transportType.alias', function () {
      return this.get('transportType.alias');
    }).readOnly(),
    transports: Ember.computed(function () {
      return [];
    }),
    pollInterval: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('POLL_INTERVAL') || 30000;
    }).readOnly(),
    transportTypeRoleGroupTableColumns: Ember.computed('transportType', function () {
      const self = this;
      return this.get('store').peekAll('transportType-roleGroup-tableColumn').filter(it => {
        return it.get('transportType.id') === self.get('transportType.id') && it.get('roleGroup.id') === self.get('sessionAccount.currentUser.roleGroup.id');
      });
    }).readOnly(),
    currentMax: Ember.computed('max', 'transportTypeMode', 'transportType.alias', function () {
      const key = 'MAX_ROWS_IN_TRANSPORT_LIST_TABLE_' + this.get('transportType.alias');

      if (this.get('transportTypeMode') === 'ALL') {
        return localStorage.getItem(key) || this.get('max');
      }

      return this.get('max') || localStorage.getItem(key) || this.get('sessionAccount').getSettingValue('TRANSPORT_PAGINATION_DEFAULT_MAX_ROWS');
    }),
    activeTableColumnNames: Ember.computed('archive', 'transportTypeRoleGroupTableColumns.[]', function () {
      return this.transportTypeRoleGroupTableColumns.filter(it => {
        return ['ACTIVE', 'ALL'].includes(it.get('visibility'));
      }).map(it => it.get('tableColumn.name'));
    }).readOnly(),
    archiveTableColumnNames: Ember.computed('archive', 'transportTypeRoleGroupTableColumns.[]', function () {
      return this.transportTypeRoleGroupTableColumns.filter(it => {
        return ['ARCHIVE', 'ALL'].includes(it.get('visibility'));
      }).map(it => it.get('tableColumn.name'));
    }).readOnly(),
    tableColumnNames: Ember.computed('archive', 'activeTableColumnNames', 'archiveTableColumnNames', function () {
      return this.get('archive') ? this.get('archiveTableColumnNames') : this.get('activeTableColumnNames');
    }).readOnly(),
    hasMore: Ember.computed('currentPage', 'totalPages', function () {
      return this.get('currentPage') < this.get('totalPages');
    }).readOnly(),
    showClearFiltersButton: false,
    _setShowClearFiltersButton: function () {
      if (this.get('searchParams.filters').length === 0 && this.get('activeFiltersQueryParams').length === 0) {
        this.set('showClearFiltersButton', false);
      } else {
        this.set('showClearFiltersButton', true);
      }
    },
    // eslint-disable-next-line ember/no-observers
    setShowClearFiltersButton: Ember.observer('searchParams.filters.@each.filterValue', 'activeFiltersQueryParams', function () {
      Ember.run.once(this, '_setShowClearFiltersButton');
    }),
    // eslint-disable-next-line ember/no-observers
    pollRequested: Ember.observer('transportType.forcedPollRequestTime', function () {
      if (this.get('transportType.forcedPollRequestTime')) {
        console.debug(`Sending poll request for transport type ${this.get('transportType.name')}..`);
        this.send('poll');
      }

      this.get('transportType').set('forcedPollRequestTime', null);
    }),
    // eslint-disable-next-line ember/no-observers
    activatePolling: Ember.observer('archive', 'transportType.loadingCompleted', function () {
      if (!this.get('archive') && this.get('transportType.loadingCompleted')) {
        console.debug(`Polling for transports of type ${this.get('transportType.name')} activated.`);
        this.set('timer', this.schedule(this.get('onPoll')));
      }
    }),
    queryParams: Ember.computed('archive', 'searchParams.{daysLimitEnabled,query,filterMode,start,stop,dateFilterProperty,lastSearch,filters.@each.filterValue}', function () {
      const params = {
        query: this.get('searchParams.query'),
        queryOpts: {
          useApiV2: this.get('searchParams.queryOpts.useApiV2'),
          globalSearchFilterOption: this.get('searchParams.queryOpts.globalSearchFilterOption')
        },
        archived: this.get('archive'),
        transportTypeId: this.get('transportType.id'),
        principalId: localStorage.getItem('principalId'),
        page: this.get('currentPage'),
        start: this.get('searchParams.start') ? moment(this.get('searchParams.start')).format() : '',
        stop: this.get('searchParams.stop') ? moment(this.get('searchParams.stop')).format() : '',
        filters: this.get('searchParams.filters'),
        daysLimitEnabled: this.get('searchParams.daysLimitEnabled'),
        max: this.get('currentMax')
      };

      if (this.get('archive') === true) {
        params.rowsPerPage = this.get('ROWS_PER_PAGE');
        params.dateFilterProperty = this.get('searchParams.dateFilterProperty');
      }

      if (this.get('sessionAccount').isGroupFilterEnabled()) {
        const filteringModeValue = localStorage.filteringMode;
        params.filterMode = filteringModeValue || this.get('searchParams.filterMode');
      }

      params.lastUpdated = new Date().getTime();
      return params;
    }),
    // po dodanie page każde ładowanie dodatkowych danych w archiwum czyści listę
    transportsProxy: Ember.computed('numberOfRenderedRows', 'transports.{[],@each.lastUpdated}', 'sortingKey', 'descDirection', function () {
      if (this.get('transports')) {
        if (this.get('archive')) {
          return this.get('transports').slice(0, this.get('numberOfRenderedRows'));
        } else {
          const sortingKey = this.get('sortingKey');
          let sortedTransports = [];

          if (this.get('sessionAccount').getSettingValue('SORT_TABLE_BY_TRANSPORT_COMPLETED') === 'true') {
            const completedTransports = this.get('transports').filterBy('completed', true).sortBy('completed', sortingKey);
            const uncompletedTransports = this.get('transports').filterBy('completed', false).sortBy('completed', sortingKey);

            if (this.get('descDirection')) {
              completedTransports.reverse();
              uncompletedTransports.reverse();
            }

            sortedTransports = uncompletedTransports.concat(completedTransports);
            return sortedTransports.slice(0, this.get('numberOfRenderedRows'));
          } else {
            sortedTransports = this.get('transports').sortBy(sortingKey);
            const transports = sortedTransports.slice(0, this.get('numberOfRenderedRows'));
            return this.get('descDirection') ? transports.reverse() : transports;
          }
        }
      } else {
        return [];
      }
    }),
    // eslint-disable-next-line ember/no-observers
    saveParamsChangeTime: Ember.observer('queryParams', function () {
      this.set('queryParamsChangeTime', new Date().getTime());
    }),
    // eslint-disable-next-line ember/no-observers
    queryInputChanged: Ember.observer('archive', 'searchParams.daysLimitEnabled', 'searchParams.query', 'searchParams.filterMode', 'searchParams.start', 'searchParams.stop', 'searchParams.dateFilterProperty', 'searchParams.filters.@each.filterValue', 'searchParams.lastSearch', 'FILTERS.@each.value', function () {
      Ember.run.debounce(this, this.loadTransports, 500);
    }),
    // eslint-disable-next-line ember/no-on-calls-in-components
    loadTransports: Ember.on('init', function () {
      if (this.isDestroyed) {
        return;
      }

      this.set('lastPoll', new Date());
      Ember.run.next(this, function () {
        const self = this; // TODO: Szycie, do przerobienia [A-10622]

        const activeFilters = this.get('FILTERS').filter(filter => filter != null && filter.value != null).map(filter => {
          return {
            'filterValue': filter.value,
            'filterType': filter.type,
            'filterKeys': filter.keys
          };
        });
        this.set('activeFiltersQueryParams', activeFilters);
        const searchParams = this.get('searchParams');
        const queryParams = this.get('queryParams'); // Filtry dodatkowe, które nie są zdefiniowane w `FILTERS`

        const additionalCustomFieldFilters = queryParams.filters.filter(filter => filter.filterKeys.includes('cfd_'));
        this.set('queryParams.filters', activeFilters.concat(additionalCustomFieldFilters));
        let list = [];
        const ttId = this.get('transportType.id');
        const relations = this.get('store').peekAll('transport-relation').filter(relation => {
          return relation.get('primaryTransportType.id') === ttId || relation.get('subordinateTransportType') === ttId;
        });

        if (relations && relations.length > 0) {
          console.debug('Transport type :: ' + ttId + ' :: used in relations, always query the server.');
          self.set('transportType.transportsTabVisited', false);
        } // Jeżeli dany typ transportu został już wczytany, to po powrocie do zakładki transportów
        // nie powinniśmy ponownie o niego pytań serwer - wystarczy wybrać odpowiednie transporty z sesji
        // i przypisać do listy `transports`.


        if (self.get('transportType.transportsTabVisited') && !this.get('currentMax')) {
          console.debug('Transport type was visited before; not querying the server..');
          list = this.get('store').peekAll('transport').filter(t => {
            return t.get('archived') === self.get('archive') && t.get('transportType.id') === self.get('transportType.id');
          });
        }

        self.get('transportType').set('transportsTabVisited', true);

        if (list.length > 0 && !self.get('archive') && // [Kacper] lamerskie rozwiązanie, ale nie wiem jak inaczej zapisać totalPages
        !queryParams.filterMode && !self.get('daysLimitSettingEnabled') && (!queryParams.filters || !queryParams.filters.length) && (searchParams === null || searchParams.query === null || searchParams.query === '')) {
          self.set('transports', list);
          self.didRender(); // Niektóre transporty, które zostały przysłane przez /updates nie zostały odświeżone w tabeli,
          // np. z powodu wybranych filtrów; teraz pytamy o nie jeszcze raz

          if (self.get('transportType.unhandledTransportIds.length')) {
            console.debug(`Transport type ${self.get('transportType.name')} has unhandled updates, refreshing ${self.get('transportType.unhandledTransportIds').join(', ')}..`);
            self.refreshSelectedTransports(self.get('transportType.unhandledTransportIds'));
          }

          return;
        }

        queryParams.max = this.get('currentMax');
        queryParams.page = this.get('currentPage');
        this.get('transportType').set('loadingCompleted', false);
        this.get('transports').clear();
        console.debug('Loading transports from server..');
        this.getTransports(queryParams).then(response => {
          if (self.isDestroyed) {
            return;
          }

          if (self.get('queryParamsChangeTime') > response.meta.requestTime) {
            console.log('Przed wczytaniem listy transportów ktoś rozpoczął filtrowanie - przerywamy operację.');
            return;
          }

          const totalPages = response.meta.totalPages;
          self.set('totalPages', totalPages);
          delete response.meta;
          self.get('store').pushPayload(response);
          self.get('transports').clear();

          if (response.transports.length === 0) {
            self.get('transportType').set('loadingCompleted', true);
          } else {
            response.transports.forEach(t => {
              self.get('transports').pushObject(self.get('store').peekRecord('transport', t.id));
            });

            if (response.transports && response.transports.length >= self.get('ROWS_INTERVAL')) {
              self.set('numberOfRenderedRows', self.get('ROWS_INTERVAL'));
            } else if (response.transports && response.transports.length > 0) {
              self.set('numberOfRenderedRows', 1);
            }
          }
        }).catch(response => {
          if (response.status === 524) {
            alert(self.get('intl').t('errors.filterRequestTimeout'));
            self.get('transportType').set('loadingCompleted', true);
          }
        });
      });
    }),

    getTransports(queryParams) {
      const useApiV2 = queryParams.queryOpts.globalSearchFilterOption && queryParams.queryOpts.globalSearchFilterOption !== 'ALL';

      if (useApiV2) {
        // Tylko na potrzeby Atlasu [A-13504] bo im wolno chodzi.
        const path = this.get('apolloApiService').APOLLO_API.TRANSPORTS.GET;
        let searchParams = {
          'transportType': queryParams.transportTypeId,
          'archived': queryParams.archived,
          'page': queryParams.page - 1,
          'limit': queryParams.max
        };

        if (queryParams.queryOpts.globalSearchFilterOption === 'CAR_REGISTRATION_NUMBER') {
          searchParams = { ...searchParams,
            'il:carRegistrationNumber': queryParams.query
          };
        } else if (queryParams.queryOpts.globalSearchFilterOption === 'ADVICE_DRIVER_NAME') {
          searchParams = { ...searchParams,
            'il:driverName': queryParams.query
          };
        }

        return this.get('apolloApiService').callApolloApi(path, null, {
          headers: {
            'APIVersion': 'v2-apollo'
          }
        }, searchParams).then(response => {
          return response.json().then(data => {
            const metaData = data.metaData; // tłumaczymy strukturę z publicznego api do apollo api

            const mergedMaps = this.mergeMaps(data.data);
            return { ...mergedMaps,
              meta: {
                totalPages: metaData.totalPages,
                currentPage: metaData.currentPage + 1,
                count: metaData.totalItems
              }
            };
          });
        });
      } else {
        const path = this.get('apolloApiService').APOLLO_API.TRANSPORTS.INDEX;
        return this.get('apolloApiService').callApolloApi(path, null, {
          body: JSON.stringify(queryParams)
        }).then(response => response.json());
      }
    },

    /**
     * Zrzyna z backendu z MapUtil#mergeMaps. Tylko na potrzeby Atlasu [A-13504] bo im wolno chodzi. Patologia.
     * @param maps
     * @returns {{}}
     */
    mergeMaps: function (maps) {
      const resp = {};
      maps.forEach(m => {
        Object.entries(m).forEach(([key, v]) => {
          if (key === 'transport') {
            // As an argument, we receive a list of maps for specific transports,
            // and as output, we need a list of transports
            key = 'transports';
          }

          const value = Array.isArray(v) ? v : [v]; // In case of 'transport' key, we receive a map as a value

          const existingValue = resp[key] || []; // eslint-disable-next-line no-prototype-builtins

          const newValues = resp.hasOwnProperty(key) ? value.filter(item => !existingValue.includes(item)) : value;
          resp[key] = [...existingValue, ...newValues];
        });
      });
      return resp;
    },
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didRender: function () {
      this._super(...arguments);

      const self = this;
      const loadingCompleted = this.get('transportType.loadingCompleted');
      Ember.run.next(function () {
        if (self.get('showColumnFilters')) {
          self.resizeTableHeader();
        }

        if (self.isDestroyed || !self.get('transports.length')) {
          return;
        }

        if (self.get('numberOfRenderedRows') < self.get('transports.length')) {
          self.renderMoreRows();
        } else if (self.get('transports.length') > 0 && !loadingCompleted) {
          self.get('transportType').set('loadingCompleted', true);
        }
      });
    },
    switchOffHighlight: function () {
      const highlightedTransportId = this.get('highlightedTransportId');

      if (highlightedTransportId) {
        const highlightedTransport = this.get('store').peekRecord('transport', highlightedTransportId);

        if (highlightedTransport) {
          highlightedTransport.set('isHighlighted', false);
        }
      }

      this.set('highlightedTransportId', null);
    },
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didDestroyElement: function () {
      this._super(...arguments);

      Ember.run.cancel(this.get('timer'));
      this.get('transportType').set('loadingCompleted', false);
      this.setProperties({
        transportType: null,
        transports: []
      });
      this.switchOffHighlight();
    },
    isTransportRowHighlightingEnabled: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('ENABLE_TRANSPORT_ROW_HIGHLIGHTING') === 'true';
    }),
    currentPage: Ember.computed('page', function () {
      return this.get('page') || '1';
    }),
    availableMaxPages: Ember.computed(function () {
      return ['25', '50', '100'];
    }),
    // Akcje
    action: '',
    actions: {
      showModal: function (template, transport) {
        this.set('action', 'showModal');
        this.sendAction('action', template, transport);
      },
      goToWarehouse: function (step) {
        this.goToWarehouse(step);
      },

      openCreateAdviceModal(transport) {
        this.openCreateAdviceModal(transport);
      },

      generateWeightReceipt(transportId) {
        this.set('action', 'generateWeightReceipt');
        this.sendAction('action', transportId);
      },

      markNotesAsReadOff(transport) {
        this.set('action', 'markNotesAsReadOff');
        this.sendAction('action', transport);
      },

      addNote(noteContent, transportId) {
        this.set('action', 'addNote');
        this.sendAction('action', noteContent, transportId);
      },

      deleteFile(file) {
        this.set('action', 'deleteFile');
        this.sendAction('action', file);
      },

      fileUploadSuccess: function (transportId) {
        this.set('action', 'fileUploadSuccess');
        this.sendAction('action', transportId);
      },
      fileUploadFail: function (transportId) {
        this.set('action', 'fileUploadFail');
        this.sendAction('action', transportId);
      },

      saveMaxRows() {
        const key = 'MAX_ROWS_IN_TRANSPORT_LIST_TABLE_' + this.get('transportTypeMode');
        localStorage.setItem(key, this.get('currentMax'));
        this.set('saveButtonMessage', this.get('intl').t('common.saved'));
      },

      incrementPage() {
        this.set('transportType.transportsTabVisited', false);
        this.set('page', String(Number(this.get('currentPage')) + 1));
        Ember.run.debounce(this, this.loadTransports, 500);
      },

      decrementPage() {
        this.set('transportType.transportsTabVisited', false);
        this.set('page', String(Number(this.get('currentPage')) - 1));
        Ember.run.debounce(this, this.loadTransports, 500);
      },

      setPage(page) {
        this.set('page', page);
        this.set('transportType.transportsTabVisited', false);
        Ember.run.debounce(this, this.loadTransports, 500);
      },

      loadMore() {
        const self = this;
        const token = this.get('session.data.authenticated.token');
        const queryParams = this.get('queryParams');
        queryParams.page = Number(queryParams.page) + 1;
        this.set('loadingMore', true); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/index',
          data: JSON.stringify(queryParams),
          type: 'POST',
          contentType: 'application/json',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (data) {
          if (!self.isDestroyed) {
            self.incrementProperty('page');
            self.setProperties({
              loadingMore: false,
              totalPages: data.meta.totalPages,
              count: data.meta.count
            });
            delete data.meta;
            self.get('store').pushPayload(data);

            if (data.transports.length === 0) {
              self.get('transportType').set('loadingCompleted', true);
            } else {
              data.transports.forEach(t => {
                self.get('transports').pushObject(self.get('store').peekRecord('transport', t.id));
              });
            }
          }
        }, function () {
          self.set('loadingMore', false);
        });
      },

      poll() {
        this.onPoll();
      },

      clearFilters() {
        this.get('FILTERS').forEach(filter => {
          if (filter != null && filter.value != null) {
            filter.set('value', null);
          }
        });
        this.get('searchParams.filters').clear();
      },

      updateHighlightedRow(transportId) {
        const highlightedTransportId = this.get('highlightedTransportId');

        if (highlightedTransportId === transportId) {
          return;
        }

        this.switchOffHighlight();
        this.set('highlightedTransportId', transportId);
      },

      scrollToClosestTransport() {
        const self = this;
        const today = moment();
        const transportTypeId = this.get('transportType.id');
        let closestTransportInternalId;
        let minDiffPast = Number.MIN_SAFE_INTEGER;
        let minDiffFuture = Number.MAX_SAFE_INTEGER;
        this.get('transports').filterBy('transportType.id', transportTypeId).forEach(function (tr) {
          const difference = moment(tr.get('earliestTimeWindowStart')).diff(today, 'minutes');

          if (difference < 0 && difference > minDiffPast) {
            minDiffPast = difference;
            closestTransportInternalId = tr.get('internalIndex');
          }
        });

        if (minDiffPast === Number.MIN_SAFE_INTEGER) {
          // nie znaleźliśmy transportu w przeszłości, więc zaczynamy szukać w przyszłości
          this.get('transports').filterBy('transportType.id', transportTypeId).forEach(function (tr) {
            const difference = moment(tr.get('earliestTimeWindowStart')).diff(today, 'minutes');

            if (difference > 0 && difference < minDiffFuture) {
              minDiffFuture = difference;
              closestTransportInternalId = tr.get('internalIndex');
            }
          });
        }

        (0, _jquery.default)('.transport-row').each(function () {
          if ((0, _jquery.default)(this).data('order-number') === closestTransportInternalId) {
            const closestTransport = self.get('transports').filterBy('internalIndex', closestTransportInternalId).get('firstObject');

            if (self.get('isTransportRowHighlightingEnabled') && !closestTransport.get('isHighlighted')) {
              closestTransport.set('isHighlighted', !closestTransport.get('isHighlighted'));
              self.send('updateHighlightedRow', closestTransport.get('id'));
            }

            const offset = (0, _jquery.default)(this).offset().top - (0, _jquery.default)(this).height() - (0, _jquery.default)('.navbar').height() - (0, _jquery.default)('.transport-table-nav').height();
            (0, _jquery.default)('html, body').animate({
              scrollTop: offset
            }, 'slow');
          }
        });
      },

      changeSortingKey: function (newKey, newDirection) {
        if (!newDirection) {
          this.set('sortingKey', this.DEFAULT_SORTING_KEY);
          this.set('descDirection', false);
        } else {
          this.set('sortingKey', newKey);
          this.set('descDirection', newDirection === 'down');
        }
      },

      openRelationsModal() {
        this.set('action', 'openRelationsModal');
        this.sendAction('action');
      }

    },

    // Metody
    refreshSelectedTransports(transportIds) {
      if (!transportIds || !transportIds.length) {
        return;
      }

      const self = this;
      const queryParams = Object.assign({}, this.get('queryParams'));
      queryParams.lastUpdatedIds = transportIds.uniq();
      queryParams.page = 1; // TODO :: apollo-api-service

      const token = this.get('session.data.authenticated.token');
      jQuery.ajax({
        url: _environment.default.serverURL + '/transports/index',
        data: JSON.stringify(queryParams),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        type: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(response => {
        self.get('transportType').get('unhandledTransportIds').removeObjects(transportIds);
        const receivedTransportIds = response.transports.map(t => t.id); // Mogliśmy nie dostać odpowiedzi dla niektórych transportów z powodu tego, że użytkownik utracił
        // do nich dostęp (np. usunięcie zlecenia, zakończenie aukcji) lub z powodu wybranych filtrów

        const unhandledTransportIds = transportIds.filter(id => !receivedTransportIds.includes(id));
        self.get('transportType').get('unhandledTransportIds').pushObjects(unhandledTransportIds);
        unhandledTransportIds.forEach(id => {
          const transportToRemove = self.get('transports').findBy('id', id.toString());

          if (transportToRemove) {
            console.log(`Removing transport #${id}..`);

            if (this.get('highlightedTransportId') === id) {
              this.set('highlightedTransportId', null);
            }

            self.get('transports').removeObject(transportToRemove);
            this.get('store').unloadRecord(transportToRemove);
          }
        });
        const openTransportId = this.get('openTransportId');
        const prevCustomFields = openTransportId ? self.getPreviousCustomFieldValuesMap(this.get('openTransportId')) : null;
        delete response.meta;
        self.get('store').pushPayload(response);
        const newCFValues = response.customFieldValues;
        response.transports.forEach(t => {
          const loadedTransport = self.get('store').peekRecord('transport', t.id);
          const transportId = loadedTransport.get('id');

          if (self.get('transports').includes(loadedTransport)) {
            console.debug(`Unloading checkpoints from transport ${loadedTransport.get('logString')}..`);
            self.unloadDeletedRecords('transport', transportId, 'checkpoint');

            if (loadedTransport.get('activeAuction.id')) {
              console.debug(`Refreshing auction for transport ${transportId}..`);
              loadedTransport.get('activeAuction').reload();
            }

            if (loadedTransport.get('courierPricings')) {
              console.debug(`Refreshing courier Pricings for transport ${transportId}..`);
              loadedTransport.get('courierPricings').reload();
            }

            if (loadedTransport.get('courierOrrder')) {
              console.debug(`Refreshing courier order for transport ${transportId}..`);
              loadedTransport.get('courierOrder').reload();
            }

            loadedTransport.get('timeWindows').forEach(tw => {
              tw.reload().then(reloadedWindow => {
                if (reloadedWindow.get('data.start') && reloadedWindow.get('data.stop')) {
                  tw.set('start', moment(reloadedWindow.get('data.start')).toDate());
                  tw.set('stop', moment(reloadedWindow.get('data.stop')).toDate());
                }
              });
            });
            loadedTransport.get('steps').forEach(s => {
              if (s.get('data.minAdviceDate') && s.get('data.maxAdviceDate')) {
                s.set('start', s.get('data.minAdviceDate'));
                s.set('stop', s.get('data.maxAdviceDate'));
              }
            });
            loadedTransport.get('indexes').forEach(index => {
              if (index.get('id') && index.get('currentState.stateName') !== 'root.loading') {
                index.reload();
              }
            });
            loadedTransport.notifyPropertyChange('lastUpdated');
            loadedTransport.notifyPropertyChange('files.length');
            loadedTransport.notifyPropertyChange('notes.length');

            if (loadedTransport.get('deleted')) {
              self.get('transports').removeObject(loadedTransport);
              console.debug(`Transport ${loadedTransport.get('logString')} has been removed from the table.`);
            } else {
              console.debug(`Transport ${loadedTransport.get('logString')} has been refreshed.`);
            }

            if (prevCustomFields && parseInt(openTransportId) === t.id) {
              const newCustomFields = newCFValues.filter(ncf => ncf.transport === t.id);
              self.markDirtyCustomFieldValues(loadedTransport, prevCustomFields, newCustomFields);
            }
          } else if (loadedTransport.get('deleted') === false && self.get('archive') !== true && !self.get('searchParams.query')) {
            // && self.get('archive') !== true dodanie zapobiega wyświetlaniu kopii transportu z archiwum,
            // które nie jest archiwalne, od razu w tabeli archiwum
            const transportBelongToCurrentTransportSupervisor = loadedTransport.get('transportSupervisor.id') === self.get('sessionAccount.currentUser.id');
            const currentUserCreatedTransport = loadedTransport.get('creator.id') === self.get('sessionAccount.currentUser.id');
            const transportBelongToCurrentTransportSupervisorUserGroup = loadedTransport.get('transportSupervisor.userGroup.id') === self.get('sessionAccount.currentUser.userGroup.id');

            if (self.get('searchParams.filterMode') === 'user' && !currentUserCreatedTransport && !transportBelongToCurrentTransportSupervisor) {
              return;
            } else if (self.get('searchParams.filterMode') === 'group' && !transportBelongToCurrentTransportSupervisorUserGroup) {
              return;
            }

            self.get('transports').addObject(loadedTransport);

            if (loadedTransport.get('previouslyActiveAuction.id') && loadedTransport.get('currentState.stateName') !== 'root.loaded.saved') {
              console.debug(`Refreshing previously active auction for transport ${transportId}..`);
              loadedTransport.get('previouslyActiveAuction').reload();
            }

            console.debug(`Transport ${loadedTransport.get('logString')} has been added to the table.`);
          }
        });
      });
    },

    // eslint-disable-next-line ember/no-on-calls-in-components
    initializeOnPollActiveTabHandling: Ember.on('init', function () {
      this.get('appTabActiveService').setOnPollHandlingObject(this, 'TRANSPORTS_TABLE');
    }),
    // eslint-disable-next-line ember/no-on-calls-in-components
    deactivateOnPollActiveTabHandling: Ember.on('didDestroyElement', function () {
      this.get('appTabActiveService').setOnPollHandlingObject(null, 'TRANSPORTS_TABLE');
    }),
    onPollWrapper: function () {
      this.onPoll();
    },

    /** @namespace json.transportIds */
    onPoll: function () {
      try {
        const self = this;
        const lastPollFormatted = moment(this.get('lastPoll')).format();
        const queryParams = this.get('queryParams');
        queryParams.lastPoll = lastPollFormatted;
        const pollQueryParams = Object.assign({}, queryParams);
        pollQueryParams.filters = null;
        const token = this.get('session.data.authenticated.token'); // TODO :: apollo-api-service

        jQuery.ajax({
          url: _environment.default.serverURL + '/transports/updates',
          data: pollQueryParams,
          type: 'GET',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          if (!self.isDestroyed && json.transportIds.length) {
            const transportIds = json.transportIds;
            self.refreshSelectedTransports(transportIds);
            self.set('lastPoll', json.pollTime);
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    // eslint-disable-next-line ember/no-on-calls-in-components
    initResizeObserver: Ember.on('init', function () {
      (0, _jquery.default)(window).off('resize');
      (0, _jquery.default)(window).on('resize', this.resizeTableHeader.bind(this));
      this.adjustPageContent('.transport-tables-container', ['.transport-table-nav']);
      (0, _jquery.default)(window).on('resize', () => this.adjustPageContent('.transport-tables-container', ['.transport-table-nav']));
    }),
    _resizeTableHeaderLater: function () {
      if (!this.showColumnFilters) {
        return;
      }

      if (this.isDestroyed) {
        return;
      }

      (0, _jquery.default)('div.transport-table-header div.cell').each(function () {
        const name = (0, _jquery.default)(this).data('column-name');
        (0, _jquery.default)("div[data-column-name='" + name + "']").each(function () {
          const windowInnerWidth = window.innerWidth;
          const numOfCells = (0, _jquery.default)('.column-names .cell').length;
          const defaultWidth = Math.floor(windowInnerWidth / (numOfCells + 1)); // -1 to poprawka na marginesy między filtrami

          const width = (0, _jquery.default)(this).closest('.table-responsive').find("td[data-column-name='" + name + "']").outerWidth(true) - 1;
          (0, _jquery.default)(this).outerWidth(width || defaultWidth);
        });
      });
      (0, _jquery.default)('.column-names').each(function () {
        (0, _jquery.default)(this).outerWidth((0, _jquery.default)(this).closest('.table-responsive').find('.transport-table').outerWidth(true));
      });
      (0, _jquery.default)('.transport-filters').each(function () {
        (0, _jquery.default)(this).outerWidth((0, _jquery.default)(this).closest('.table-responsive').find('.transport-table').outerWidth(true));
      });
    },
    resizeTableHeader: function () {
      Ember.run.once(this, '_resizeTableHeaderLater');
    },
    schedule: function (f) {
      const self = this;
      return Ember.run.later(self, function () {
        if (!self.isDestroyed) {
          f.apply(self);
          self.set('timer', self.schedule(f));
        }
      }, self.get('pollInterval'));
    },

    renderMoreRows() {
      const interval = this.get('ROWS_INTERVAL');
      const numberOfRenderedRows = this.get('numberOfRenderedRows'); // console.debug(`+ numberOfRenderedRows: ${numberOfRenderedRows}, interval: ${interval}, L :: ${this.get('transports.length')}`);

      if (numberOfRenderedRows + interval < this.get('transports.length')) {
        this.set('numberOfRenderedRows', numberOfRenderedRows + interval);
      } else if (numberOfRenderedRows < this.get('transports.length')) {
        this.set('numberOfRenderedRows', numberOfRenderedRows + 1);
      } else if (this.get('transports.length') > 0) {
        this.get('transportType').set('loadingCompleted', true);
      }
    }

  });

  _exports.default = _default;
});