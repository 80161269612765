define("apollo/pods/transport-types/transport-order-settings/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    access: Ember.A(['ROLE_SUPER_USER']),
    beforeModel: function (transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: async function (params) {
      const transportTypeId = params.id;
      const transportType = this.store.peekRecord('transport-type', transportTypeId);
      const transportOrderSettings = await this.store.queryRecord('transport-order-setting', {
        transportTypeId
      });
      return Ember.RSVP.hash({
        transportType,
        transportOrderSettings
      });
    },
    actions: {
      willTransition() {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.get('controller').setProperties({
          model: null,
          errors: null,
          successMessage: null
        });
      },

      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});