define("apollo/pods/transport-types/transport-order-settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    actions: {
      save() {
        this.set('errors', []);
        const transportOrderSettings = this.get('model.transportOrderSettings');
        transportOrderSettings.set('transportType', this.get('model.transportType'));

        if (!transportOrderSettings.validate()) {
          return;
        }

        transportOrderSettings.save().then(() => {
          this.send('refreshModel');
          const msg = this.get('intl').t('common.saveSuccessMessage');
          this.set('successMessage', msg);
          window.scrollTo({
            top: 0
          });
        }).catch(response => {
          this.set('errors', response.errors);
          window.scrollTo({
            top: 0
          });
        });
      },

      cancel() {
        this.get('model.transportOrderSettings').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      }

    }
  });

  _exports.default = _default;
});