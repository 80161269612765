define("apollo/pods/warehouse/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    PromiseArray,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    languageService: Ember.inject.service('language-service'),
    users: hasMany('user', {
      async: true
    }),
    usersWithAccess: hasMany('user', {
      async: true
    }),
    ramps: hasMany('ramp', {
      async: true
    }),
    company: belongsTo('company', {
      async: true
    }),
    address: belongsTo('address', {
      async: true,
      deepEmbedded: ['country']
    }),
    warehouseZones: hasMany('warehouseZone', {
      async: true
    }),
    warehouseLocations: hasMany('warehouseLocation', {
      async: true
    }),
    assortmentGroups: hasMany('assortmentGroup', {
      async: true
    }),
    transportTypes: hasMany('transportType', {
      async: true
    }),
    idx: attr('number'),
    checkedTransportTypesIds: attr(),
    name: attr('string'),
    englishName: attr('string'),
    nameInAppropriateLanguage: Ember.computed('', function () {
      const properLabel = this.get('languageService').findProperLanguageVersion('name', 'englishName');
      return this.get(properLabel);
    }),
    workStartHour: attr('number'),
    workEndHour: attr('number'),
    windowConstantTime: attr('number'),
    timeWindowOffsetInterval: attr('number', {
      defaultValue() {
        return 15;
      }

    }),
    timeWindowDefaultLengthInMinutes: attr('number'),
    timeWindowMustOccupyEntireRamp: attr('boolean'),
    snapToClosestWindowInterval: attr('number', {
      defaultValue() {
        return 0;
      }

    }),
    shouldFindFreeSpotAfterDelay: attr('boolean'),
    enabled: attr('boolean'),
    weighingIncluded: attr('boolean'),
    artrIncluded: attr('boolean'),
    timeWindowLengthLongerThanWarehouseWorkingHours: attr('boolean'),
    integrationCode: attr('string'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      englishName: {
        presence: {
          message: 'blank'
        }
      },
      windowConstantTime: {
        presence: {
          message: 'info'
        }
      },
      timeWindowOffsetInterval: {
        presence: {
          message: 'info'
        }
      },
      snapToClosestWindowInterval: {
        presence: {
          message: 'info'
        }
      },
      timeWindowDefaultLengthInMinutes: {
        presence: {
          message: 'blankSelect'
        }
      },
      timeWindowMustOccupyEntireRamp: {
        presence: {
          message: 'blank'
        }
      },
      timeWindowLengthLongerThanWarehouseWorkingHours: {
        presence: {
          message: 'blank'
        }
      },
      workStartHour: {
        numericality: {
          allowBlank: false,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 23,
          onlyInteger: true,
          message: 'invalidNumber'
        }
      },
      workEndHour: {
        numericality: {
          allowBlank: false,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 24,
          onlyInteger: true,
          message: 'invalidNumber'
        },
        custom: [{
          validation: function (key, value, model) {
            return parseInt(model.get('workStartHour')) <= parseInt(value);
          },
          message: 'endBeforeStart'
        }]
      },
      address: {
        relations: ['belongsTo']
      },
      idx: {
        custom: [{
          validation: function (key, value, model) {
            if (!model.get('id')) {
              return true;
            }

            return !Ember.isEmpty(value) && value >= 0 && value < model.store.peekAll('warehouse').length;
          },
          message: function (key, message, self) {
            return self.get('intl').t('warehouse.errors.idx.invalidIdxNumber', {
              numberOfWarehouses: this.store.peekAll('warehouse').length - 1
            }).toString();
          }
        }]
      }
    },
    workStartTime: Ember.computed('workStartHour', function () {
      return moment(this.getStartOnDay(new Date())).format('HH:mm');
    }),
    workEndTime: Ember.computed('workEndHour', function () {
      return moment(this.getEndOnDay(new Date())).format('kk:mm');
    }),
    formattedWorkTime: Ember.computed('workStartTime', 'workEndTime', function () {
      return `${this.get('workStartTime')}-${this.get('workEndTime')}`;
    }),
    minutes: Ember.computed('', function () {
      return [0, 15, 30, 45];
    }),

    getStartOnDay(date) {
      const dateString = moment(date).format('YYYY-MM-DD');
      const h = this.get('workStartHour');
      return moment(`${dateString} ${h}:0`, 'YYYY-MM-DD HH:mm');
    },

    getEndOnDay(date) {
      const dateString = moment(date).format('YYYY-MM-DD');
      const h = this.get('workEndHour');
      return moment(`${dateString} ${h}:0`, 'YYYY-MM-DD HH:mm');
    },

    enabledRamps: Ember.computed('ramps.@each.enabled', function () {
      return PromiseArray.create({
        promise: this.get('ramps').then(loadedRamps => {
          return loadedRamps.filterBy('enabled', true);
        })
      });
    }),
    sortedRamps: Ember.computed('enabledRamps.@each.idx', function () {
      return this.get('ramps').sortBy('idx:desc');
    }),
    sortedZones: Ember.computed('warehouseZones.[]', function () {
      return this.get('warehouseZones').filterBy('enabled', true).sortBy('idx');
    }),
    enabledRampsForCurrentUser: Ember.computed('sessionAccount.currentUser.ramps', function () {
      const self = this;
      return PromiseArray.create({
        promise: self.get('sessionAccount.currentUser.ramps').then(loadedRamps => {
          return loadedRamps.filterBy('warehouse.id', self.get('id'));
        })
      });
    }),
    loadedDays: Ember.computed('', function () {
      return [];
    }),
    nameTrimmed: Ember.computed('nameInAppropriateLanguage', function () {
      return this.get('nameInAppropriateLanguage').replace(/ /g, '-');
    }),
    hasAssortmentGroups: Ember.computed('assortmentGroups.[]', function () {
      return this.get('assortmentGroups.length') > 0;
    }),
    isWarehouse24h: Ember.computed('', function () {
      const d1 = new Date();
      const d2 = new Date();
      d1.setHours(...this.get('workStartTime').split(':'));
      d2.setHours(...this.get('workEndTime').split(':'));
      return Math.abs((d1 - d2) / 1000 / 3600) === 24;
    })
  });

  _exports.default = _default;
});