define("apollo/router", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType
  }); // eslint-disable-next-line array-callback-return

  Router.map(function () {
    this.route('files', function () {});
    this.route('file-sets', function () {
      this.route('status', {
        path: '/status/:id'
      });
    });
    this.route('users', function () {
      this.route('show', {
        path: '/:user_id'
      });
      this.route('create');
      this.route('edit', {
        path: '/:user_id/edit'
      });
      this.route('activity', {
        path: '/:user_id/activity'
      });
      this.route('ramps', {
        path: '/:user_id/ramps'
      });
      this.route('notifications', {
        path: '/:user_id/notifications'
      });
      this.route('queues', {
        path: '/:user_id/queues'
      });
    });
    this.route('auth', function () {
      this.route('login');
      this.route('remind');
      this.route('reset-password');
      this.route('activate-account');
    });
    this.route('companies', function () {
      this.route('show', {
        path: '/:company_id'
      });
      this.route('create');
      this.route('invite', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: '/:company_id/:partnershipType/invite'
      });
      this.route('ramp-edition', {
        path: '/:company_id/rampEdition'
      });
      this.route('allowed-domains', {
        path: '/:company_id/allowedDomains'
      });
      this.route('users');
    }); // Domyślna ścieżka do tabeli transportów - po wybraniu adresu /transports/,
    // użytkownik zostanie przeniesiony do widoku tabeli pierwszego z dostępnych typów,
    // lub do widoku wszystkich typów - w zależności od ustawień.
    // eslint-disable-next-line ember/no-capital-letters-in-routes

    this.route('defaultTransports', {
      path: '/transports/'
    });
    this.route('transports', function () {
      this.route('index', {
        path: '/:type'
      });
      this.route('show');
      this.route('create');
      this.route('archive', {
        path: '/archive/:type'
      });
      this.route('print', {
        path: '/print/:id'
      });
    }); // eslint-disable-next-line ember/no-capital-letters-in-routes

    this.route('warehouseZones', function () {
      // eslint-disable-next-line ember/no-capital-letters-in-routes
      this.route('addZone', {
        path: '/:warehouse_id/addZone'
      });
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('warehouses', function () {
      this.route('schedule', {
        path: '/:warehouse_id/:date_string/:delta/schedule/'
      });
      this.route('combined-schedule', {
        path: '/:warehouse_id/:date_string/:delta/:step_id/schedule/'
      });
      this.route('hd', {
        path: '/:warehouse_id/:date_string/:delta/hd'
      });
      this.route('template', {
        path: '/:warehouse_id/:date_string/template'
      });
      this.route('edit', {
        path: '/:id'
      });
      this.route('create'); // eslint-disable-next-line ember/no-capital-letters-in-routes

      this.route('addRamp', {
        path: '/:warehouse_id/:warehouse_zone_id/addRamp'
      });
    });
    this.route('services', function () {
      this.route('show');
    });
    this.route('settings', function () {
      this.route('edit', {
        path: '/:id'
      });
      this.route('create');
    });
    this.route('ramps', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('workflows', function () {
      this.route('edit', {
        path: '/:id'
      });
    }); // eslint-disable-next-line ember/no-capital-letters-in-routes

    this.route('algorithmSettings', function () {
      this.route('edit', {
        path: '/algorithmSettings/:id'
      });
      this.route('create');
    });
    this.route('invitations', function () {
      this.route('create');
      this.route('handle');
      this.route('review');
      this.route('show', {
        path: '/:id'
      });
    });
    this.route('applies-settings', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('groups-settings', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('transport-types', function () {
      this.route('edit', {
        path: '/:id/edit'
      }); // eslint-disable-next-line ember/no-capital-letters-in-routes

      this.route('createDefinition', {
        path: '/:id/createDefinition'
      });
      this.route('settings', {
        path: '/:id/settings'
      });
      this.route('transaction-settings', {
        path: '/:id/transaction-settings'
      });
      this.route('create');
      this.route('package-type-settings', {
        path: '/:id'
      });
      this.route('auction-settings', {
        path: ':id/auction-settings'
      });
      this.route('courier-settings', {
        path: '/:id/courier-settings'
      });
      this.route('cmr-settings', {
        path: '/:id/cmr-settings'
      });
      this.route('artr-settings', {
        path: '/:id/artr-settings'
      });
      this.route('schedule-settings', {
        path: '/:id/schedule-settings'
      });
      this.route('transport-order-settings', {
        path: '/:id/transport-order-settings'
      });
    });
    this.route('authorities', function () {
      this.route('index', {
        path: '/:id'
      });
      this.route('others', {
        path: '/:id/others'
      });
      this.route('create');
      this.route('aggregate');
    });
    this.route('authority-restrictions', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('reset-password');
    this.route('forbidden');
    this.route('/', {
      path: '/:alias'
    });
    this.route('super-admin', function () {
      this.route('create');
    });
    this.route('terms', function () {
      this.route('review');
    });
    this.route('custom-field-definitions', function () {
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('products', function () {
      this.route('create');
      this.route('edit', {
        path: '/products/:id'
      });
    });
    this.route('carriers', function () {});
    this.route('price-list', function () {
      this.route('index', {
        path: '/:type'
      });
    });
    this.route('role-groups', function () {
      this.route('create');
    });
    this.route('no-accessible-transport-types');
    this.route('assortment-groups', function () {
      this.route('create', {
        path: '/:warehouse_id'
      }); // eslint-disable-next-line ember/no-shadow-route-definition

      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('warehouse-locations', function () {
      this.route('create', {
        path: '/:warehouse_id'
      }); // eslint-disable-next-line ember/no-shadow-route-definition

      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('gates', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('tasks', function () {
      this.route('edit', {
        path: '/:task_id'
      });
    });
    this.route('queue-status', function () {});
    this.route('classification-resources', function () {
      this.route('index');
    });
    this.route('squares', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('queues', function () {
      // eslint-disable-next-line ember/no-capital-letters-in-routes
      this.route('addQueue', {
        path: '/:square_id/addQueue'
      });
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('places', function () {
      // eslint-disable-next-line ember/no-capital-letters-in-routes
      this.route('addPlace', {
        path: '/:square_id/:queue_id/addPlace'
      });
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('announcements', function () {
      this.route('manage');
      this.route('create');
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('square-settings', function () {
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('mode-of-transportation', function () {
      this.route('index');
      this.route('create');
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('algorithm-settings', function () {
      this.route('index');
      this.route('create');
      this.route('edit');
    });
    this.route('combining-relations-wizard', function () {});
    this.route('user-groups', function () {
      this.route('index', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: '/:companyId'
      });
      this.route('create', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: '/:companyId/create'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('notifications', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id'
      });
    });
    this.route('truck-types', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('addresses', function () {
      this.route('index', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: ':companyId'
      });
    });
    this.route('workers', function () {
      this.route('index', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: ':companyId'
      });
    });
    this.route('drivers', function () {
      this.route('index', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: ':companyId'
      });
    });
    this.route('trucks', function () {
      this.route('index', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: ':companyId'
      });
    });
    this.route('partnerships', function () {
      this.route('index', {
        // eslint-disable-next-line ember/routes-segments-snake-case
        path: ':companyId'
      });
    });
    this.route('cmr-settings', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('artr-settings', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('transport-type-schedule-settings', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('transport-order-settings', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('statistics-report', function () {
      this.route('index', {
        path: '/'
      });
    });
    this.route('planning', function () {
      this.route('index', {
        path: '/'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});